@import '../../assets/styles/sizes.scss';

.header {
  position: relative;
  // display: flex;
  align-items: center;
  justify-content: center;
  // padding: 8px 32px;

  @include media-sm {
    justify-content: space-between;
    background-color: var(--primary);
    border: 0;
    padding: 8px 16px;
    z-index: 3;
  }

  .closeIcon {
    float: right;
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
}
