@import '../../assets/styles/sizes.scss';

.layout {
  display: flex;
  min-height: 100vh;
}

.mainContent {
  position: relative;
  width: calc(100% - 236px);

  @include media-xl {
    width: calc(100% - 200px);
  }

  @include media-sm {
    width: 100%;
  }
}

.mainContentClosed {
  position: relative;
  width: calc(100% - 100px);

  @include media-xl {
    width: calc(100% - 85px);
  }

  @include media-sm {
    width: 100%;
  }
}

.content {
  padding: 50px 50px;

  @include media-sm {
    padding: 12px 20px;
  }
}
