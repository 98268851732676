:root {
  --gallery: #ededed;
  --light-silver: #e5e5e5;
  --silver: #cccccc;
  --catskill-white: #f7fbfb;
  --white: #ffffff;
  --alto: #dfdfdf;
  --light-gray: #a7a7a7;
  --gray: #919191;
  --boulder: #7a7a7a;
  --scorpion: #5c5c5c;
  --tundora: #444444;
  --mine-shaft: #242424;
  --black: #050505;
  --primary: #7b2c29;
  --secondary: #fff7f5;
  --tertiary: #ece4e4;
}
