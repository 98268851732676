@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/colors.scss';

.sidebar {
  // width: 236px;
  color: var(--port-black);
  padding: 24px 0;
  overflow: hidden;
  border-right: 1px solid var(--gray);
  background-color: var(--primary);

  @include media-xl {
    width: 184px;
  }

  @include media-sm {
    display: none;
  }

  .sidebarHeaderOpen {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    // padding: 0 13px 0 24px;

    .menuIconButton {
      border: none;
      background: none;
      cursor: pointer;
      // height: 30px;
      // padding-left: 30px;

      @include media-xl {
        padding: 5px;
      }
    }
  }

  .sidebarHeaderClosed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    .menuIconButton {
      border: none;
      background: none;
      cursor: pointer;
      height: 30px;

      @include media-xl {
        padding: 5px;
      }
    }
  }

  .logoOnly {
    width: 50px;
    height: auto;
    padding: 0;
  }

  .profileInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 24px;
    margin-top: 20px;
    margin-bottom: 16px;
    border-top: 1px solid var(--gallery);
    border-bottom: 1px solid var(--gallery);
    color: var(--white);

    .profileInfoImg {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .profileInfoTexts {
      display: flex;
      // margin-left: 12px;
      // width: 100%;

      .profileInfoUsername {
        font-size: 16px;
      }

      .profileInfoEmail {
        font-size: 12px;
      }
    }
  }

  .listWrapper {
    font-size: 16px;
    width: 100%;

    .list {
      display: inline-flex;
      flex-direction: column;
      line-height: 24px;
      width: 100%;
    }

    .item {
      color: var(--white);
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 26px;
      transition: all 150ms ease;
      width: 100%;

      img {
        width: 20px;
        max-width: 25px;
        font-weight: bold;

        &:hover {
          background-color: var(--tertiary);
          color: var(--black);
          fill: var(--black);
        }
      }

      span {
        padding-left: 18px;
      }

      &:hover {
        background-color: var(--tertiary);
        color: var(--black);
        img {
          fill: var(--black);
          color: var(--black);
        }
      }
    }
    .centered {
      justify-content: center;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--primary);
    border: none;
    cursor: pointer;
    word-break: break-all;
    padding: 10px;
    margin: 0;
    justify-content: space-between;
    width: 100%;

    // &:hover {
    //   color: var(--black);
    //   & .logoutIcon path {
    //     fill: var(--black);
    //   }
    // }

    @include media-sm {
      background-color: var(--alizarin-crimson);

      &:hover .logoutIcon path {
        fill: var(--black);
      }
    }
  }

  .email {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin: 0;
      padding: 0;
      line-height: normal;
    }
    @include media-sm {
      display: none;
    }
  }

  .emailClosed {
    display: none;
    justify-content: center;
  }

  .logoutIcon {
    margin-left: 8px;
    width: 20px;

    @include media-sm {
      width: 24px;
      height: 24px;
      margin-left: 0;

      & path {
        fill: var(--black);
      }
    }
  }

  .logoutIconClosed {
    margin-left: 0;
  }
}

.open {
  width: 236px;
}

.closed {
  width: 100px;
}
